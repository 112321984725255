import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/SEO';
import Header from '../components/Header';
import ContentRow from '../components/ContentRow';
import ImgW from '../components/ImgW';
import { commonHeaderColor } from '../util';


const MyNeoSurfPage = ({ data }) => (
  <>
    <SEO
      title="MyNeoSurf E-wallet - Online Casino &amp; Gambling Payments"
      description="Sign up with MyNeoSurf - the perfect solution for all your gaming needs. Deposit &amp; Cash-out safely via MyNeoSurf e-wallet."
      alternateLanguagePages={[
        {
          path: 'myneosurf',
          language: 'en'
        },
        {
          path: 'myneosurf',
          language: 'fr'
        },
        {
          path: 'myneosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">MyNeoSurf - The Perfect E-wallet Solution</h1>
            <p className="textColorDark">If you're already a Neosurf user, you'll know that it's a voucher-based payment system. You may not be aware that there's actually an online component to NeoSurf as well: this is called MyNeoSurf. Just like the vouchers, it's <strong>safe, secure and simple</strong>. It's a method of making online payments and is both quick and convenient.</p>
            <a href="https://www.myneosurf.com/en_GB" className="button">Get MyNeosurf</a>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">What is MyNeoSurf?</h2>
            <p className="textColorDark">MyNeoSurf is essentially an <strong>e-wallet</strong>. You can upload funds to it using NeoSurf vouchers you've purchased at points of sale. Once your online account has been topped up with funds, you can send money to friends and make online payments. The e-wallet is currently used by over 350,000 people and can be used on more than 20,000 websites, from Amazon and League of Legends to online casino websites such as AU Slots, SpaceLilly and Lucky Star Casino. You can even withdraw money from ATMs if you have a Neocash MasterCard.</p>
            <h2 className="smaller textUppercase">Why MyNeoSurf?</h2>
            <ul>
              <li className="textColorDark">Your favorite e-wallet (Neteller, Skrill) has recently been banned from use in your country?</li>
              <li className="textColorDark">Prefer safe, secure and simple deposits to online gambling sites?</li>
              <li className="textColorDark">You love the option to cash-out casino chips to an e-wallet and withdraw funds via ATM?</li>
            </ul>
            <p className="textColorDark">MyNeosurf may just be the perfect solution! All the functions of the e-wallet are there for you. From secure deposits to lightning fast withdrawals!</p>
          </div>
        </div>
        <div>
          <Img fluid={data.myneosurfCard.childImageSharp.fluid} alt="MyNeoSurf e-wallet &amp; Online Casinos" />
        </div>
      </div>
    </ContentRow>
    <ContentRow backgroundColor="#eee" padV>
      <h2 className="smaller textUppercase">How it works</h2>
      <p className="textColorDark">In order to use MyNeoSurf, you need to register and create your online account. Luckily, doing so is very straightforward and shouldn't take up much time at all. Once your account's set up and ready to go, you can fund it using the codes from <strong>NeoSurf vouchers</strong>. In addition to this, you can fund your account using <strong>credit cards or bank transfers</strong>.</p>
      <ImgW center fluid={data.fundAccount.childImageSharp.fluid} alt="Fund your account with vouchers, cards or bank transfer" />
      <p>With your account, you're able to easily manage your vouchers and get really good exchange rates should any of your transactions involve differences in currency. In order to withdraw money from your MyNeoSurf account, you can use a bank transfer (a fee of 1.5% applies) or your Neocash MasterCard (a fee of 2% applies). Usually, when using NeoSurf vouchers, you just type in the code and have the funds added to your account. With a MyNeoSurf account, you can keep all your codes in one place and manage them.</p>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <Img fluid={data.neoCash.childImageSharp.fluid} alt="NeoCash Mastercard" />
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">Neocash MasterCard</h3>
            <p>This a debit card that's linked to your MyNeoSurf online account. You can apply for one once your account's been set up. As its name suggests, it's a type of MasterCard and it can therefore be used anywhere MasterCard is accepted. You can use it both online and offline.</p>
            <p>As previously mentioned, you can use it to make ATM withdrawals from your MyNeoSurf account. To do so, you simply have to enter your PIN at the ATM and choose how much to withdraw.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textColorDark">Difference between Neosurf and MyNeosurf</h2>
      <p>MyNeoSurf is the 'next step' solution from the same company that runs NeoSurf. The difference is huge. <strong>NeoSurf is a prepaid voucher</strong>, which, by its nature is somewhat limited transaction solution. Limited by a number of factors like the fact you have to physically buy them (the vouchers) – meaning you have to plan and buy the vouchers ahead. Limited by the fact you can only send funds with NeoSurf vouchers, but can't receive them.</p>
      <ImgW center fluid={data.myNeoVsNeo.childImageSharp.fluid} alt="MyNeosurf VS Neosurf" />
      <p>MyNeoSurf, on the other hand, doesn't have any of those limitations. <strong>MyNeoSurf is an e-wallet</strong>. E-wallets are <strong>online / digital / electronic wallets</strong>. You don't have to plan your purchases ahead, you can both send and receive funds, you can even order <strong>MyneoSurf Debit Card</strong> and physically spend your funds in stores or even withdraw them via ATM.</p>
      <h2 className="smaller textColorDark">E-wallets 101</h2>
      <p>E-wallets or electronic wallets have been around for quite some time now. They first saw daylight back in late 1990s, although they haven't really been widely adopted and used by consumers at the time. Advancements in our technology, internet speed and accessibility, as well as people’s electronic and digital literacy changed that. E-wallets are now widely used across the globe, which is not surprising, considering all the benefits they provide.</p>
      <ul>
        <li className="textColorDark">They are fast and secure</li>
        <li className="textColorDark">They are widely accepted</li>
        <li className="textColorDark">You can receive, send and spend funds and even track your spending habits efficiently</li>
        <li className="textColorDark">You can order a debit card connected to your e-wallet and use it just like your bank card</li>
        <li className="textColorDark">You can transfer your funds between your e-wallet and your bank account easily</li>
      </ul>
      <h3 className="smaller textColorDark">So how do these e-wallets exactly work?</h3>
      <p>It’s rather simple, actually.</p>
      <p>You go to the e-wallet’s web page and create an account, just like any other account (like an email account). That’s about it. You are now an owner of an e-wallet account. You can log in to your account and start using it. The first thing you’ll want to do is ‘top-up’ your account with some money. You can simply <strong>connect your bank card</strong> with your e-wallet – send some funds from your bank account to your e-wallet account. Now you have an active e-wallet with money in it! You can use that money on your e-wallet to purchase products and services from millions of web-shops, games and vast number of other services online. You can send the funds to your friends and family that have an e-wallet ultra-fast, secure and cheap. You can receive funds from your friends and family as well.</p>
      <ImgW center fluid={data.eWallets.childImageSharp.fluid} alt="E-wallets – Widely accepted online" />
      <p>Furthermore, you can order a <strong>physical e-wallet debit card</strong> and use it to purchase goods and products in shops around the world or even withdraw cash from ATMs in all corners of the globe. Nowadays, most e-wallets even have mobile apps you can download and install on your smartphone to receive, send and spend your funds whenever and wherever you are even ‘on-the-go’, as well as track, monitor and even limit your spending habits which can be huge help for your monthly budget.</p>
      <p>All in all, PROS of using e-wallet in your everyday life are numerous, while CONS are non-existent.</p>
      <p>MyNeoSurf e-wallet is one of the newer e-wallet solutions, taking the market like a storm.</p>
      <ul>
        <li className="textColorDark">It’s widely accepted.</li>
        <li className="textColorDark">It’s simple, fast and secure.</li>
        <li className="textColorDark">It offers a dozen languages for your convenience.</li>
      </ul>
      <h2 className="smaller textColorDark">MyNeoSurf in Online Gaming</h2>
      <p>As already mentioned, MyNeoSurf e-wallet can be used to purchase products and services in a number of web-shops, booking and gaming sites; from one of the most used web-shops – <u>Amazon</u>, to one of the most played games – <u>League of Legends</u>, but it can be used in a number of Casinos, as well!</p>
      <p>What are the benefits of MyNeoSurf in online gambling, compared to vouchers and credit cards?</p>
      <h3 className="smaller textColorDark">MyNeoSurf e-wallet VS Vouchers</h3>
      <p>Vouchers, as previously mentioned, must be physically purchased ahead on a specific locations only, while you can ‘top-up’ your MyNeoSurf anytime and anywhere.</p>
      <p>You can use vouchers only to deposit to your player account, while you can use MyNeoSurf e-wallet to, both, deposit and withdraw your funds.</p>
      <h3 className="smaller textColorDark">MyNeoSurf e-wallet VS Credit Cards</h3>
      <p>You can use both for deposits and withdrawals, but you will wait your withdrawals to your credit card for days, while receiving them almost <strong>instantly to your MyNeoSurf e-wallet</strong>.</p>
      <p>Depending on payment processors and your nationality, your credit cards can be accepted in some Casinos while rejected in others. You can also have the problem that your deposits will be accepted but withdrawals to the same credit card won’t, again depending on payment processors, licenses, your bank, etc. You will never have that problem with MyNeoSurf e-wallet. <strong>MyNeoSurf e-wallet is accepted in many casinos</strong> already with a tendency to be accepted in even more in the future. If accepted as a transaction method by the casino, you will never have any issues with withdrawals to your MyNeoSurf e-wallet.</p>
      <ImgW center fluid={data.wideRange.childImageSharp.fluid} alt="Wide range of online casinos are MyNeoSurf-friendly" />
      <p>To wrap it up, let’s mention yet another amazing benefit of MyNeoSurf e-wallet in your online gambling. It concerns your expenditure and budgeting. The amazing thing is you <strong>can keep track of the amount of funds you want to allocate to your gambling</strong> and keep it under control. As it is separate from your bank account, you can pre-decide the amount of funds you want to play with on a weekly or monthly basis and send only that amount from your bank account to your MyNeoSurf e-wallet. That way, you can make sure you don’t gamble more than you want. Amazing, right?</p>
      <p>You set aside a predetermined amount for your gaming. If you lose, you are aware that it’s time to stop since there’s no more funds on your MyNeoSurf e-wallet. If you win, you can spend it in web-shops or in brick-and-mortar shops with your MyNeoSurf e-wallet debit card, or even withdraw good old cash from any ATM.</p>
      <p className="textAlignCenter"><strong>Looking for online casinos that accept MyNeoSurf deposits?</strong></p>
      <div className="grid c-4 bigGap">
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.emu.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">
              <Img fluid={data.sevenSB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.777bay.com/?a_aid=5a6fb263dabe6">
              <Img fluid={data.sevenB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default MyNeoSurfPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    myneosurfCard: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-card.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fundAccount: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/Fund-your-account-with-vouchers-cards-or-banks.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 524,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neoCash: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/neocashmaster.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    myNeoVsNeo: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/MyNeosurf-VS-Neosurf.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    eWallets: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/E-wallets-Widely-accepted-online.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    wideRange: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/Wide-range-of-online-casinos-are-MyNeoSurf-friendly.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lucky-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/betbtc-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/emucasino-logo-bw.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777bay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777slotsbay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
